/** @jsxImportSource @emotion/react */
import { useState, useEffect, useMemo, HTMLAttributes } from 'react';
import SpinnersButton, { SpinnersButtonIcon, IButtonTooltips } from './Spinners/SpinnersButton';
import SpinnersDropdown from './Spinners/SpinnersDropdown';
import { IProgressItem } from './IProgressItem';
import { ClickAwayListener } from '@mui/material';
import { wrapperStyle } from '../mike-topbar-dropdown';

export const SPINNER_TIMEOUT = 6000;
export const PROGRESS_ITEM_TIMEOUT = 6000;

interface IProps extends HTMLAttributes<HTMLElement> {
  progressItems: Array<IProgressItem>;
  spinnerTimeout?: number;
  tooltipTitles?: IButtonTooltips;
  onClickItemButton?: (id: string) => void;
}

/**
 * @name MikeTopbarProgressSpinner
 * @summary Shows progress in the topbar. Typically related to notifications on currently-running actions.
 */
export const MikeTopbarProgressSpinner = (props: IProps) => {
  const { progressItems, spinnerTimeout, tooltipTitles, onClickItemButton } = {
    spinnerTimeout: SPINNER_TIMEOUT,
    ...props,
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const [previousProgressItemsCount, setPreviousProgressItemsCount] = useState(0);

  useEffect(() => {
    if (showDropdown) {
      // Close the dropdown after a while
      const timeout = setTimeout(() => {
        setShowDropdown(false);
      }, spinnerTimeout);

      // Clear the timeout when the component unmounts or when `showDropdown` changes
      return () => {
        clearTimeout(timeout);
      }
    } 
  }, [showDropdown, spinnerTimeout]);

  useEffect(() => {
    if (progressItems && progressItems.length > previousProgressItemsCount){
      setShowDropdown(true)
      setPreviousProgressItemsCount(progressItems.length)
    }
  }, [progressItems, previousProgressItemsCount])


  const btnIcon = useMemo(() => {
    const done = progressItems.every((itm) => itm && itm.done === true) && progressItems.length > 0;
    const errorCount: number = progressItems.filter((itm) => itm && itm.error === true).length;
    return  errorCount > 0 ? 'alert' : done ? 'checkmark' : 'spinner' as SpinnersButtonIcon
  }, [progressItems])

  useEffect(() => {
    if (btnIcon === 'checkmark'){
      setShowDropdown(false)     
    }
  }, [btnIcon])

  const toggleDropdown = () => {   
    setShowDropdown(!showDropdown);
  };

  // Only show tooltips when closed:
  const tooltips: IButtonTooltips | undefined = showDropdown
    ? { spinner: '', alert: '', checkmark: '' }
    : tooltipTitles;

  return (
    <div css={wrapperStyle}>
      {progressItems.length > 0 && (
        <>
          <SpinnersButton onClick={toggleDropdown} icon={btnIcon} tooltipTitles={tooltips} />
          {showDropdown && (
            <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
              <div /* this div fixes ref error for ClickAwayListener */>
                <SpinnersDropdown progressItems={progressItems} onClickItemButton={onClickItemButton}/>
              </div>
            </ClickAwayListener>
          )}
        </>
      )}
    </div>
  );
};

export default MikeTopbarProgressSpinner;
