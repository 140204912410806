/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';
import { IProgressItem } from '../IProgressItem';
import {  Typography, ButtonBase, Tooltip } from '@mui/material';
import { MikeProgressBar } from './MikeProgressBar';
import mikeSharedTheme from '../../../styles/mikeSharedTheme';
import { css } from '@emotion/react'
import Delete from '../../../icons/Delete.svg?react';

const rootStyle = css`
  display: flex;
`;

const progressSectionStyle = css`
  width: 95%;
  margin: 10px 0px 16px 0px;
  &:first-of-type: {
    margin-top: 0px;
  };
  &:last-of-type: {
    margin-bottom: 0px;
  };
`;

const progressSectionWithButtonStyle = css`
  width: 85%;
  margin: 10px 0px 16px 0px;
  &:first-of-type: {
    marginTop: 0px;
  };
  &:last-of-type: {
    margin-bottom: 0px;
  };
`;

const progressErrorTextStyle = css`
  color: ${mikeSharedTheme.palette.error.main};
`;

const ellipStyle = css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 85%;
  white-space: nowrap; 
`;

interface IProps extends React.HTMLAttributes<HTMLElement> {
  progressItem: IProgressItem;
  onClickItemButton?: (id: string) => void;
}

/**
 * @name ProgressSection
 * @summary Renders a section of the MikeTopbarDropdown that should be removed after
 * a delay once it's done or has failed.
 * Not intended to be used without MikeProgressBar.
 */
function ProgressSection(props: IProps) {
  const { progressItem, onClickItemButton } = props; 

  const showButton = useMemo(() => {
    return onClickItemButton !== undefined && progressItem.canBeDeleted
  }, [onClickItemButton, progressItem]) 

  const item = () => {
    return (
    <div css={css`${showButton ? progressSectionWithButtonStyle : progressSectionStyle}`}>
      <Tooltip title={progressItem.title}>
      <Typography css={css`${ellipStyle}; ${progressItem.error && progressErrorTextStyle}`} variant="body2">        
         {progressItem.title}       
      </Typography>
      </Tooltip>
      <MikeProgressBar
        variant="determinate"
        color="secondary"
        value={progressItem.progressValue}
        style={{ marginTop: '7px' }}
      />
    </div>)
  }

  return (
    showButton ?
    <div css={rootStyle}>
      {item()}      
      <ButtonBase onClick={() => onClickItemButton && onClickItemButton(progressItem.id)}>
        <Delete />
      </ButtonBase>     
    </div> : item()
  ); 
}

export default ProgressSection;
