/** @jsxImportSource @emotion/react */
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from "@mui/material/styles";

export const MikeProgressBar = styled(LinearProgress)(({theme}) => ({
  height: 8,
  borderRadius: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.secondary.light
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 4,
    backgroundColor: theme.palette.secondary.main
  }
}));
